import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06b331bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-12" }
const _hoisted_2 = {
  for: "filter-home-view_date-range",
  class: "form-label cor_texto_1"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = { class: "row g-4" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = {
  key: 0,
  class: "col-12"
}
const _hoisted_8 = { style: {"color":"var(--cor_indicadores_4)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_FilterBaseV2 = _resolveComponent("FilterBaseV2")!

  return (_openBlock(), _createBlock(_component_FilterBaseV2, {
    id: "filter-home-view",
    title: "Dashboard",
    activeModal: _ctx.isActiveModal,
    closeModal: _ctx.isCloseModal,
    showCompanyField: true,
    showStoreField: false,
    displayAllCompanies: false,
    displayAllStores: false,
    hideFilter: false,
    staticModal: true,
    hideClose: false,
    disabledClick: false,
    disabled: _ctx.wrongField,
    showGoToBack: false,
    "onWatch:changesGlobalFields": _cache[2] || (_cache[2] = (data) => _ctx.handleResetFields(data)),
    "onWatch:requestFlow": _cache[3] || (_cache[3] = (data) => _ctx.handleExecuteSearch(data))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, [
          false
            ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" Selecione um intervalo de datas: ")
        ]),
        _createVNode(_component_el_config_provider, { locale: _ctx.ptBr }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.startDate,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
                  type: "month",
                  placeholder: "Mês inicial",
                  format: "MM/YYYY"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.endDate,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event)),
                  type: "month",
                  placeholder: "Mês final",
                  format: "MM/YYYY"
                }, null, 8, ["modelValue"])
              ]),
              (_ctx.dateWarning.isError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.dateWarning.message), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["locale"])
      ])
    ]),
    _: 1
  }, 8, ["activeModal", "closeModal", "disabled"]))
}